import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'principal',
    loadChildren: () => import('./components/principal/principal.module').then( m => m.PrincipalPageModule)
  },
  {
    path: 'contactame',
    loadChildren: () => import('./components/contactame/contactame.module').then( m => m.ContactamePageModule)
  },
  {
    path: 'sobre-mi',
    loadChildren: () => import('./components/sobre-mi/sobre-mi.module').then( m => m.SobreMiPageModule)
  },
  {
    path: 'proyecto',
    loadChildren: () => import('./components/proyecto/proyecto.module').then( m => m.ProyectoPageModule)
  },
  {
    path: 'servicios',
    loadChildren: () => import('./components/servicios/servicios.module').then( m => m.ServiciosPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
